<template>
    <div>

    <HeroHead>
        <div class="terms">
        <div class="container px-0">
            <div class="row">
            <div class="col-lg-12 pt-5 pt-lg-0 d-flex flex-column justify-content-center">
                <div class="title fw-bold gilroy-medium mb-3 text-blue-dk-1 text-uppercase">Terms Of Service</div>
            </div>
            </div>
        </div>
        </div>
    </HeroHead>

    <div class="container px-0">
        <div class="row content">

            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">OVERVIEW</div>
                <div class="d-flex flex-column">
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        This website is operated by Spark. Throughout the site, the terms “we”, “us” and “our” refer to Spark. Spark offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
                    </div>
                
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.
                    </div>
                
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.
                    </div>
                
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        Any new features or tools which are added to the current Service shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 1 - ONLINE SERVICE TERMS</div>
                <div class="d-flex flex-column">
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        You may not use our service or products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        You must not transmit any worms or viruses or any code of a destructive nature.
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        A breach or violation of any of the Terms will result in an immediate termination of your Services.
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 2 - GENERAL CONDITIONS</div>
                <div class="d-flex flex-column">
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        We reserve the right to refuse service to anyone for any reason at any time.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</div>
                <div class="d-flex flex-column">
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</div>
                <div class="d-flex flex-column">
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        Prices for our service or products are in USD and NGN (depending on your country) and subject to change without notice.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        We reserve the right at any time to modify or discontinue the Service  or plan(or any part or content thereof) without notice at any time.
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service or plan
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 5 - EARLY UPGRADES</div>
                <div class="d-flex flex-column">
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        Spark provides 5 days money-back guarantee to allow potential clients to assess if the service is the right fit for them. If a client wishes to forego the 5 days money-back guarantee they will be required to participate in an onboarding call before their subscription can be made active in order to ensure they have a clear understanding of the scope of the service.
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        Clients who forego the 5 days money-back guarantee and determine that the service is not the right fit for them are eligible to request a refund should they decide to cancel the subscription before the end of the 5 day of their first subscription period on Spark (i.e. before their next billing date). 
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 6 - QUARTERLY, BI-ANNUAL, AND ANNUAL SUBSCRIPTIONS</div>
                <div class="d-flex flex-column">
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        Payment for any quarterly, bi-annual, or annual subscription must be made in full, in order for the subscription to commence.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        At the end of a given term of a quarterly, bi-annual, or annual subscription, the subscription will renew for the same duration, at the same payment rate, if no further action is requested or taken by the individual who has initiated the subscription.
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        It is the sole responsibility of those who sign up for a Spark subscription to cancel the service, ahead of their billing date, if they do not intend to renew the service after a subscription period.
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 7 - USE OF THE SERVICE</div>
                <div class="d-flex flex-column">
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        7.1 You may use the Service for as many projects as you prefer under the applicable subscription and as appropriate based on the scope of your subscription so long as your account is in good standing, i.e. you have paid your monthly subscription fee and have complied with the overall Terms of Service.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        7.2 Spark accepts unlimited requests and revisions, however the volume and speed of turnarounds is determined by several factors including  i) the working hours of the assigned design team ii) the number of subscriptions a client has iii) the type of subscription(s) a client has; iv) the number of requests a client makes; v) and the complexity of requests. As these factors can fluctuate, Spark makes no guarantee of a set amount of design outputs that we can deliver with a single subscription.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        7.3 Human error happens. Sometimes it may be on our end. Sometimes it may be on yours. So be sure to carefully review each and every output you receive from Spark to make sure they are sufficient for their intended use. If any errors or inaccuracies are detected, it is your responsibility to request revisions within the scope of the Spark service.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        7.4 You must be the owner, or have the necessary rights, licenses, and authorization to distribute, all of the information, data or materials that you provide to Spark while using the Service. And by submitting any information, data or materials to Spark you are granting the Service a worldwide, royalty free, non-exclusive license to access and use the information, data, or materials to provide the Service.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        7.5 You are, and will be, the sole and exclusive owner of all right, title, and interest in and to the design outputs your receive from Spark, including all intellectual property rights therein. Notwithstanding the foregoing, the terms of this Section 7.5 are subject to your compliance with this Agreement, your full payment of applicable amounts due and the terms of Sections 7.6, 7.7, and 9 below.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        7.6 In the course of providing the Services, we may use certain pre-existing materials as outlined in “Section 9 - Stock Services”. We and our licensors are, and will remain, the sole and exclusive owners of all right, title, and interest in and to any pre-existing materials. We grant you a perpetual, limited, royalty-free, non-transferable, non-sub licensable, worldwide license to use, display, and distribute any pre-existing materials to the extent incorporated in, combined with, or otherwise necessary for the use of the Services or design outputs. We expressly reserve all other rights in and to such pre-existing materials.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        7.7 In the event that you wish to sell any of the design outputs you receive from Spark through it's Service, you must advise your design team of this at the time at which you make your design request. If you and/or your company will not be the end-user of any given design output and you will be reselling any given design output, you must advise your Spark design team of this so that they can ensure that all elements of your design outputs have the appropriate licenses and/or are created as custom elements for your commercial use (i.e. resale).
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        7.8 We reserve the right, but are not obligated, to limit the sales of our services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any services that we offer. All descriptions of services or service pricing are subject to change at any time without notice, at the sole discretion of us. We reserve the right to discontinue any service at any time. Any offer for any service made on this site is void where prohibited.
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 8 - PORTFOLIO LICENSE</div>
                <div class="d-flex flex-column">
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        You hereby grant tryspark.com a limited, nonexclusive, non-sublicensable, royalty-free worldwide license, hereby known as the “Portfolio License”, to use, publish, and display any deliverables that we develop in connection with the Spark Service for the purpose of marketing and advertising for Tryspark.com, and/or to display any Spark Kanban board and/or Spark client dashboard that we develop in connection with the Spark Service for the purpose of demonstrating how the Tryspark.com service works.
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        You may revoke the Portfolio License at any time by sending written notice to support@tryspark.com If you revoke the Portfolio License, Tryspark.com will stop using your deliverables for marketing and advertising purposes going forward, however, your deliverables may continue to exist elsewhere online such as where the deliverables have been used by others in accordance with the Portfolio License.
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 9 - STOCK SERVICES</div>
                <div class="d-flex flex-column">
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        9.1 “Licensed Content” means content that Spark owns or license, including artwork, stock photographs, audio, typeface, video, designs, and writings. While Spark clients are, and will be, the sole and exclusive owners of all right, title, and interest in and to their graphic design and video design project, the Licensed Content incorporated in their graphic design project is subject to the license described below. No rights are granted to you other than as expressly set forth herein. You grant us a perpetual, irrevocable, worldwide, nonexclusive, transferable, sublicensable right and license to commercially exploit in any manner any feedback, suggestions or recommendations that you provide to us.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        9.2 To the extent that we license Licensed Content from any third party, you agree to comply with the relevant third-party license. Except with our written permission, you may not: (i) sell, resell, rent, lease, sublicense, assign, grant a security interest in, or otherwise transfer any part of your rights to use Licensed Content apart from a design deliverable prepared by us or as part of a design product for your own personal use; (ii) change, alter, adapt, translate, convert, modify, or make any derivative works of any Licensed Content; (iii) falsely represent that you are the original creator of any Licensed Content; (iv) use Licensed Content in a pornographic, defamatory, or other unlawful manner; (vi) use Licensed Content in any way that allows others to download, extract, or redistribute Licensed Content as a standalone file or work.(vii) use Licensed Content that features models or property in connection with a subject that would be unflattering or unduly controversial to a reasonable person (for example, sexually transmitted diseases), must indicate: (1) that the content is being used for illustrative purposes only, and (2) any person depicted in the content is a model.
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        9.3 Our licensors and we retain ownership over Licensed Content licensed from them, and incorporated into your graphic design deliverable. We reserve the right to terminate, revoke, or withdraw all licenses upon your failure to comply with any provisions of this Agreement. In the event of any termination, you will have no further right to make use of the Licensed Content, which may include the Licensed Content that we have included in your design deliverable.
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 10 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</div>
                <div class="d-flex flex-column">
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account or workspace, the same credit/debit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit/debit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 11 - REFUNDS</div>
                <div class="d-flex flex-column">
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        It is the sole responsibility of those who sign up and wants to get a 5 day money back guarantee to cancel the service within the trial period if they are not satisfied with the service, have not used the service within the trial period, or do not intend to use the service beyond the trial.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        It is the sole responsibility of those who sign up for a Spark subscription to cancel the service, ahead of their billing date, if they do not intend to use the service during a subscription period. Refunds will not be granted due to inactivity by a Spark client, or if a Spark client has forgotten to cancel their subscription prior to their billing date.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        Once a subscription payment has been processed, the service will be delivered until the final date of the subscription period. This is void in the event that a refund is requested and granted.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        If a refund request is approved for a month-to-month subscription, the refund will be issued for the equivalent of either a pro-rated monthly payment or one monthly payment, at Spark's discretion, to the original payment method within 3-4 business days. Refund requests for month-to-month subscriptions will not be approved for more than one pro-rated monthly payment or one monthly payment.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        If a refund request is approved for a quarterly, bi-annual, or annual subscription, the refund will be issued in the amount equivalent to the cost of the remainder of the subscription term, less the cost of the subscription used to date. The cost of the subscription used to date will be calculated based on the full price of the monthly subscription and all promotions and promotional pricing applied to the quarterly, bi-annual, or annual subscription will no longer be valid. Refunds approved, at Spark's discretion, will be issued to the original payment method within 3-4 business days.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-1">
                        Eligibility for refund is nulled if…
                    </div>
                    <ul class="list ps-0 mb-3 list-unstyled">
                        <li class="gilroy-regular mb-2 fs-18 lh-21">1. You REQUESTED for more than 3 design request or a motion graphics within 5-day of the first month .</li>
                        <li class="gilroy-regular mb-2 fs-18 lh-21">2. You are past the 7-day refund policy of the first month.</li>
                        <li class="gilroy-regular mb-2 fs-18 lh-21">3. You request or download source files.</li>
                    </ul>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-1">Plans ineligible for refunds</div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-1">The following plans are not eligible for refunds.</div>
                    <ul class="list ps-0 mb-3 list-unstyled">
                        <li class="gilroy-regular mb-2 fs-18 lh-21">1. Plans that are a part of a sponsored program</li>
                        <li class="gilroy-regular mb-2 fs-18 lh-21">2. Partnership programs</li>
                    </ul>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        To initiate a refund request please send an email to billings@tryspark.com outlining the grounds on which you are submitting your refund request. All refund requests are reviewed, approved and processed at the sole discretion of Spark and are subject to a 5% administrative fee (5% of the value of the refund). The 5% administrative fee covers the non-refundable charges applied by the secure payment processing platform.
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 12 - OPTIONAL TOOLS</div>
                <div class="d-flex flex-column">
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        You acknowledge and agree that we provide access to such tools” as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 13 - THIRD-PARTY LINKS</div>
                <div class="d-flex flex-column">
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        Certain content, products and services available via our service may include materials from third-parties.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. 
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 14 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</div>
                <div class="d-flex flex-column">
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party's intellectual property or these Terms of Service.
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 15 - PERSONAL INFORMATION</div>
                <div class="d-flex flex-column">
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        Your submission of personal information through the store is governed by our Privacy Policy. 
                    </div>
                    <router-link to="/privacy-policy" class="fs-18 lh-20 gilroy-regular cursor-pointer text-blue">View our Privacy Policy here.</router-link>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 16 - ERRORS, INACCURACIES AND OMISSIONS</div>
                <div class="d-flex flex-column">
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 17 - PROHIBITED USES</div>
                <div class="d-flex flex-column">
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 18 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</div>
                <div class="d-flex flex-column">
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        18.1 We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free. We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable. It is the client's responsibility to review the outputs they receive from Spark to ensure that they are sufficient for their intended use. If any errors or inaccuracies are detected, it is a client's responsibility to request revisions within the scope of the Spark service. In no case shall Spark, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for a client's use and/or implementation of an output received from Spark.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        18.2 You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.
                    </div>
                    <div class=" fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        18.3 You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        18.4 In no case shall Spark, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 19 - INDEMNIFICATION</div>
                <div class="d-flex flex-column">
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        You agree to indemnify, defend and hold harmless Spark and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 20 - SEVER-ABILITY</div>
                <div class="d-flex flex-column">
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 21 - TERMINATION</div>
                <div class="d-flex flex-column">
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our services, or when you cease using our site.
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our services (or any part thereof).
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 22 - ENTIRE AGREEMENT</div>
                <div class="d-flex flex-column">
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 23 - CHANGES TO TERMS OF SERVICE</div>
                <div class="d-flex flex-column">
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1 mb-3">
                        You can review the most current version of the Terms of Service at any time at this page.
                    </div>
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.

                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-column mb-5">
                <div class="gilroy-medium fs-32 lh-35 mb-4">SECTION 24 - CONTACT INFORMATION</div>
                <div class="d-flex flex-column">
                    <div class="fs-18 lh-21 gilroy-regular text-wrap text-purple-dk-1">
                        Questions about the Terms of Service should be sent to us at support@tryspark.com.
                    </div>
                </div>
            </div>



        </div>
    </div>


    </div>
</template>



<style lang="scss" scoped>
.title {
    font-size: calc(2rem + .3vw);
}

@media (min-width: 600px) {
    .content{
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

@media (min-width: 1024px) {
    .title {
        font-size: calc(3.75rem + .3vw);
    }
}



@media (max-width: 1024px) {
    .terms {
        padding-top: 60px;
        padding-bottom: 90px;
    }
}

@media (max-width: 500px) {
    .title{
        text-align: center;
    }
    .content{
        padding: 30px 20px;
    }

    .terms {
        padding-top: 30px;
        padding-bottom: 90px;
    }
}

@media (min-width: 1200px) {

    .terms, .content {
        padding: 100px 150px
    }
}
</style>