// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/clients/sidebarImg.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3E%3Ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3E%3Ccircle r=%273%27 fill=%27%231227E2%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".workspaces .creator[data-v-1e73581d]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-repeat:no-repeat}.workspaces .form-switch .form-check-input[data-v-1e73581d]{width:50px;height:20px}.workspaces .form-switch .form-check-input[data-v-1e73581d]:focus{box-shadow:none!important;border-color:rgba(0,0,0,.25);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.workspaces .form-switch .form-check-input[data-v-1e73581d]:checked{background-color:var(--spa-color-blue-lt-1);border-color:var(--spa-color-blue-lt-1);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
