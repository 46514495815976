import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-39be805a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex justify-content-between"
}
const _hoisted_3 = {
  key: 0,
  class: "my-auto text-secondary-muted fw-400 fs-14 lh-23"
}
const _hoisted_4 = {
  key: 1,
  class: "my-auto text-secondary-muted fw-400 fs-14 lh-23 ms-auto"
}
const _hoisted_5 = ["aria-valuenow"]
const _hoisted_6 = { class: "fs-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label || (_ctx.showPercentage && !_ctx.isMultipleBars))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.label)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.label), 1))
            : _createCommentVNode("", true),
          (_ctx.showPercentage && !_ctx.isMultipleBars)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.percentage) + "%", 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["progress bg-blue-lt-1", `hpx-${_ctx.height}`])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bars, (bar, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: _normalizeClass(["progress-bar", [_ctx.barClasses,`${bar.bgColor} w-${bar.percentage}`]]),
          role: "progressbar",
          "aria-valuenow": bar.percentage,
          "aria-valuemin": "0",
          "aria-valuemax": "100"
        }, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.showPercentageOnBar || _ctx.isMultipleBars ? `${bar.percentage}%` : ''), 1)
        ], 10, _hoisted_5))
      }), 128))
    ], 2)
  ]))
}