// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/home/pricing-mini.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pricing-mini[data-v-acf54400]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:100% 100%;padding-top:150px;padding-bottom:200px}.title[data-v-acf54400]{font-size:60px;font-family:gilroy-medium}.details[data-v-acf54400]{font-size:30px;max-width:766px}.pricing-title[data-v-acf54400]{font-size:30px;line-height:35px;font-family:gilroy-medium}.pricing-description[data-v-acf54400]{font-size:20px;line-height:23px;font-family:gilroy-regular}@media(max-width:500px){.title[data-v-acf54400]{font-size:40px;font-family:gilroy-bold}.details[data-v-acf54400]{font-size:30px}}@media(min-width:1200px){.pricing-description[data-v-acf54400]{max-width:292px}.pricing-card[data-v-acf54400]{max-width:340px}.pricing-mini[data-v-acf54400]{padding:150px 150px 200px}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
