// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/home/footer-curved.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".curved-bg[data-v-73eb737f]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:contain}.spa-footer a[data-v-73eb737f]{color:var(--spa-white);text-decoration:none}@media(max-width:576px){.curved-bg[data-v-73eb737f]{height:90px}}@media(min-width:576px)and (max-width:768px){.curved-bg[data-v-73eb737f]{height:150px}}@media(min-width:769px)and (max-width:1024px){.curved-bg[data-v-73eb737f]{height:200px}}@media(max-width:800px){.spa-footer[data-v-73eb737f]:has(>.copyright){justify-content:center}.copyright[data-v-73eb737f]{text-align:center}}@media(min-width:1200px){.curved-bg[data-v-73eb737f]{height:220px}.spa-footer[data-v-73eb737f]{padding-left:150px;padding-right:150px}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
