// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3E%3Ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3E%3Ccircle r=%273%27 fill=%27%231227E2%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-v-2fb6e9a3] .add-services-class .modal-body,[data-v-2fb6e9a3] .add-sub-class .modal-body{background-color:var(--spa-color-blue-lt-1)}@media(min-width:992px){[data-v-2fb6e9a3] .add-services-class .modal-lg,[data-v-2fb6e9a3] .add-services-class .modal-xl,[data-v-2fb6e9a3] .add-sub-class .modal-lg,[data-v-2fb6e9a3] .add-sub-class .modal-xl{--bs-modal-width:1500px!important}}[data-v-2fb6e9a3] .add-sub-class .modal-body{height:680px}[data-v-2fb6e9a3] .cancel-sub-class .robot{top:-290px;left:245px;z-index:1}[data-v-2fb6e9a3] .cancel-sub-class .robot .perfect-center{box-shadow:0 5px 26px #e7e9fc}.billings[data-v-2fb6e9a3]{min-height:180px}.form-switch .form-check-input[data-v-2fb6e9a3]{width:50px;height:20px}.form-switch .form-check-input[data-v-2fb6e9a3]:focus{box-shadow:none!important;border-color:rgba(0,0,0,.25);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.form-switch .form-check-input[data-v-2fb6e9a3]:checked{background-color:var(--spa-color-blue-lt-1);border-color:var(--spa-color-blue-lt-1);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}@media screen and (max-width:991px){.cta[data-v-2fb6e9a3]{margin-top:10px!important}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
